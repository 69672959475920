//ACTIVE SLIDE
.swiper-slide-active {
    .slide-title .title-small {
        animation-name: title-text-animation;
        animation-duration: 2s;
        animation-fill-mode: forwards;
    }
    .svg-main {
        animation: svg-main-animation 1s;
        animation-fill-mode: forwards;
    }
}

//title text animation
@keyframes title-text-animation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

//svg main animation
@keyframes svg-main-animation {
    0% {
        transform: scale(0.7);
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}