                @import "imports.scss";
                @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');
                ////////////////////////////////////////////////////////////////////////////////////
                //VARIABLES
                ////////////////////////////////////////////////////////////////////////////////////
                //text size
                $text-xs: 12;
                $text-sm: 14;
                $text-md: 25;
                $text-lg: 30;
                $text-xl: 40;
                //inner-padding
                $inner-padding-xs: 30px;
                $inner-padding-sm: 50px;
                $inner-padding-md: 50px;
                $inner-padding-lg: 90px;
                $primary-color: #010102;
                $secondary-color: #ffffff;
                $tertiary-color: #525252;
                $light-grey-color: #e2e8f0;
                //z-indexes
                $z-1: -1;
                $z-2: -1;
                $z-3: 1005;
                $z-4: 1007;
                $z-5: 2000;
                $z-6: 3000;
                //block min heights
                $block-height-xs: 725px;
                $block-height-sm: 725px;
                $block-height-md: 725px;
                $block-height-lg: 725px;
                $block-height-xl: 725px;
                // @import "fullscreen.scss";

                ////////////////////////////////////////////////////////////////////////////////////
                //COLOURS
                ////////////////////////////////////////////////////////////////////////////////////
                //set in cms
                .colour-ui-bg {
                    background-color: var(--theme-color) !important;
                }

                .color-white {
                    color: $secondary-color !important;
                }


                ////////////////////////////////////////////////////////////////////////////////////
                //GENERAL
                ////////////////////////////////////////////////////////////////////////////////////
                body {
                    font-family: $font-family;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 1.5em;
                    color: $primary-color;
                    background-color: $secondary-color;
                    animation: fadeInAnimation ease 2s;
                    animation-iteration-count: 1;
                    animation-fill-mode: forwards;
                    padding-top: 91px;
                    // border: 30px solid red;
                }

                body.scrolling {
                    padding-top: 51px;
                }

                @keyframes fadeInAnimation {
                    0% {
                        opacity: 0;
                    }

                    100% {
                        opacity: 1;
                    }
                }

                html,
                body {

                    height: 100%;
                    margin: 0;

                }

                html:not(.home) {
                    min-height: 100vh;
                    height: auto;
                }

                body:not(.home) {

                    min-height: 100vh;
                    height: auto;
                }

                a {
                    &:focus {
                        outline: none;
                    }
                }

                h1,
                h2,
                h3,
                h4,
                h5,
                h6 {
                    font-family: $font-family-josefin;
                }

                h1 {
                    font-size: 44px;
                    line-height: 44px;
                }

                h6 {
                    font-weight: 600;
                }

                hr {
                    border-top: 1px solid $primary-color;
                }





                ////////////////////////////////////////////////////////////////////////////////////
                //TEXT
                ////////////////////////////////////////////////////////////////////////////////////

                .lead {
                    line-height: 26px;
                }

                ////////////////////////////////////////////////////////////////////////////////////
                //UTILITIES
                ////////////////////////////////////////////////////////////////////////////////////
                .rounded-30 {
                    border-radius: 30px;
                }
                .overflow-hidden{
                    overflow: hidden;
                }

                ////////////////////////////////////////////////////////////////////////////////////
                //NAVIGATION
                ////////////////////////////////////////////////////////////////////////////////////
                .start-header {
                    opacity: 1;
                    transform: translateY(0);
                    padding: 30px 0;
                    // box-shadow: 0 10px 30px 0 rgba(138, 155, 165, 0.15);
                    -webkit-transition: all 0.3s ease-out;
                    transition: all 0.3s ease-out;
                }

                .start-header.scroll-on {
                    box-shadow: 0 5px 10px 0 rgba(138, 155, 165, 0.15);
                    padding: 10px 0;
                    -webkit-transition: all 0.3s ease-out;
                    transition: all 0.3s ease-out;
                }

                .start-header.scroll-on .navbar-brand img {
                    height: 24px;
                    -webkit-transition: all 0.3s ease-out;
                    transition: all 0.3s ease-out;
                }

                .navigation-wrap {
                    background-color: $secondary-color;
                    position: fixed;
                    width: 100%;
                    top: 0;
                    left: 0;
                    z-index: 2000;
                    -webkit-transition: all 0.3s ease-out;
                    transition: all 0.3s ease-out;
                }

                .navbar-brand img {
                    height: 32px;
                    width: auto;
                    display: block;
                    filter: brightness(10%);
                    -webkit-transition: all 0.3s ease-out;
                    transition: all 0.3s ease-out;
                }

                .navbar-brand {
                    font-family: $font-family-josefin;
                    font-weight: bold;
                    font-size: 25px;
                    transition: opacity 0.3s ease-out;
                    opacity: 1;

                    &:hover {
                        opacity: .5;
                    }
                }

                .navbar-toggler {
                    float: right;
                    border: none;
                    padding-right: 0;
                }

                .navbar-toggler:active,
                .navbar-toggler:focus {
                    outline: none;
                }

                .navbar-light .navbar-toggler-icon {
                    width: 24px;
                    height: 17px;
                    background-image: none;
                    position: relative;
                    border-bottom: 1px solid $primary-color;
                    transition: all 300ms linear;
                }

                .navbar-light .navbar-toggler-icon:after,
                .navbar-light .navbar-toggler-icon:before {
                    width: 24px;
                    position: absolute;
                    height: 1px;
                    background-color: $primary-color;
                    top: 0;
                    left: 0;
                    content: '';
                    z-index: 2;
                    transition: all 300ms linear;
                }

                .navbar-light .navbar-toggler-icon:after {
                    top: 8px;
                }

                .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
                    transform: rotate(45deg);
                }

                .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
                    transform: translateY(8px) rotate(-45deg);
                }

                .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
                    border-color: transparent;
                }

                .nav-item:hover .nav-link {
                    color: $primary-color !important;
                }

                .nav-item.active .nav-link {
                    color: $primary-color !important;
                }

                .nav-link {
                    color: $primary-color !important;
                    font-weight: normal;
                    transition: all 200ms linear;
                    font-size: 20px;
                    position: relative;
                    padding: 5px 0 !important;
                    display: inline-block;
                }

                .nav-item:after {
                    position: absolute;
                    bottom: -5px;
                    left: 0;
                    width: 100%;
                    height: 2px;
                    content: '';
                    background-color: var(--theme-color);
                    opacity: 0;
                    transition: all 200ms linear;
                }

                .nav-item:hover:after {
                    bottom: 0;
                    opacity: 1;
                }

                .nav-item.active:hover:after {
                    opacity: 0;
                }

                .nav-item {
                    position: relative;
                    transition: all 200ms linear;
                    padding-top: 5px;
                    padding-bottom: 5px;
                }

                .nav-item.active:after {
                    bottom: 0px !important;
                    opacity: 1 !important;
                    background-color: $tertiary-color !important;
                }

                .nav-item .dropdown-menu {
                    transform: translate3d(0, 10px, 0);
                    visibility: hidden;
                    opacity: 0;
                    max-height: 0;
                    display: block;
                    padding: 0;
                    margin: 0;
                    transition: all 200ms linear;
                }

                .nav-item.show .dropdown-menu {
                    opacity: 1;
                    visibility: visible;
                    max-height: 999px;
                    transform: translate3d(0, 0px, 0);
                }

                .dropdown-menu {
                    padding: 10px !important;
                    margin: 0;
                    letter-spacing: 1px;
                    color: $primary-color;
                    background-color: $secondary-color;
                    border: none;
                    border-radius: 3px;
                    box-shadow: 0 5px 10px 0 rgba(138, 155, 165, 0.15);
                    transition: all 200ms linear;
                }

                .dropdown-toggle::after {
                    display: none;
                }

                .dropdown-item {
                    padding: 6px 15px;
                    color: $primary-color;
                    border-radius: 2px;
                    transition: all 200ms linear;
                    font-size: 17px;
                }

                .dropdown-item:hover,
                .dropdown-item:focus {
                    color: $secondary-color;
                    background-color: $primary-color;
                }

                .navbar-nav li:last-child {
                    .dropdown-menu {
                        right: 0;
                        left: auto;
                    }
                }

                //dm
                .nav-item {
                    .dropdown-menu {
                        opacity: 0;
                        transition: all .4s;
                    }
                }

                .nav-item.show {
                    .dropdown-menu {
                        display: block !important;
                        opacity: 1;
                    }
                }

                //JUMPING FIX
                .xs,
                .sm {
                    .nav-item {
                        .dropdown-menu {
                            height: 0 !important;
                            display: none;
                        }
                    }

                    .nav-item.show {
                        .dropdown-menu {
                            height: auto !important;
                            display: block;
                        }
                    }
                }




                ////////////////////////////////////////////////////////////////////////////////////
                //BREADCRUMB
                ////////////////////////////////////////////////////////////////////////////////////
                .breadcrumb-box {
                    position: relative;
                    left: 0;
                    right: 0;
                    z-index: 1000;

                    a {
                        color: $primary-color;
                        opacity: 1;
                        transition: opacity 0.5s;

                        &:hover {
                            color: $primary-color;
                            opacity: .5;
                            text-decoration: none;
                        }
                    }

                    i {
                        font-size: 12px;
                        display: inline-block;
                        margin: 0 3px;
                        vertical-align: -1px;
                    }
                }

                ////////////////////////////////////////////////////////////////////////////////////
                //HEADER
                ////////////////////////////////////////////////////////////////////////////////////
                html>body .block-main {
                    height: auto;
                }

                /*-------------------- header images --------------------*/
                /* Zoom in Keyframes */

                @-webkit-keyframes zoomin {
                    0% {
                        transform: scale(1);
                    }
                    50% {
                        transform: scale(1.3);
                    }
                    60% {
                        transform: scale(1.3);
                    }
                    70% {
                        transform: scale(1.3);
                    }
                    75% {
                        transform: scale(1.3);
                    }
                    76% {
                        transform: scale(1.3);
                    }
                    100% {
                        transform: scale(1);
                    }
                }

                @keyframes zoomin {
                    0% {
                        transform: scale(1);
                    }
                    50% {
                        transform: scale(1.3);
                    }
                    60% {
                        transform: scale(1.3);
                    }
                    70% {
                        transform: scale(1.3);
                    }
                    75% {
                        transform: scale(1.3);
                    }
                    76% {
                        transform: scale(1.3);
                    }
                    100% {
                        transform: scale(1);
                    }
                }

                .block-main .image {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    background-size: cover;
                    // background-image: url("http://wallpapercave.com/wp/LXR5gFx.png");
                    background-position: center;
                    background-repeat: no-repeat;
                    // transition: transform 6s linear;
                    z-index: $z-1;
                    transition: 3s;
           
                }

                .block-main.active .image {
                    -webkit-animation: zoomin 15s ease-in forwards;
                    animation: zoomin 15s ease-in forwards;
                    transition: all .5s ease-in-out;
                    transform-origin: top center;
                }


                /*-------------------- header block --------------------*/

                .block-main {
                    border: $inner-padding-xs solid $secondary-color;
                    height: 100%;
                    min-height: 100%;
                    position: relative;
                    padding: $inner-padding-xs;
                }

                .tag-text {
                    color: $primary-color;
                    text-decoration: underline;
                }

                ////////////////////////////////////////////////////////////////////////////////////
                //COMMON BLOCK ELEMENTS
                ////////////////////////////////////////////////////////////////////////////////////
                .image-gradient {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 101%;
                    height: 100%;
                    z-index: $z-1;
                    background: rgb(0, 0, 0);
                    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8701855742296919) 54%, rgba(0, 0, 0, 0.8673844537815126) 100%);
                }


                ////////////////////////////////////////////////////////////////////////////////////
                //SKILLBAR
                ////////////////////////////////////////////////////////////////////////////////////
                .skillbar {
                    position: relative;
                    display: block;
                    width: 100%;
                    // margin-bottom: 30px;
                }

                .skillbar-bar:before {
                    background: $light-grey-color;
                    content: "";
                    height: 3px;
                    position: absolute;
                    width: 100%;
                    z-index: -1;
                }

                .skillbar-bar {
                    height: 3px;
                    width: 0px;
                    // background-color: var(--theme-color);
                    background-color: $primary-color;
                    transition-property: width, background-color;
                }

                .skillbar-title {
                    color: $primary-color;
                    font-size: 11px;
                    font-weight: 600;
                    text-transform: uppercase;
                    display: inline-block;
                }

                .skill-bar-percent {
                    // float: right;
                    display: inline-block;
                    color: $primary-color;
                    font-size: 11px;
                    font-weight: 600;
                    text-transform: uppercase;
                }

                ////////////////////////////////////////////////////////////////////////////////////
                //TAGS
                ////////////////////////////////////////////////////////////////////////////////////
                .tags {
                    a {
                        transition: all 0.3s;

                        &:hover {
                            color: $primary-color !important;
                            background-color: $light-grey-color !important;
                            border-color: $light-grey-color !important;
                        }
                    }
                }

                .tags-scroll {
                    position: relative;
                }


                .tags-scroll-internal {
                    position: relative;
                    overflow-x: scroll;
                    overflow-y: hidden;
                    white-space: nowrap;
                    height: 50px;
                }

                .tags-scroll-internal a:last-child {
                    margin-right: 30px;
                }

                .tags-scroll:after {
                    content: "";
                    display: block;
                    height: 50px;
                    width: 30px;
                    right: 0px;
                    top: 0;
                    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 35%, rgba(255, 255, 255, 1) 100%);
                    position: absolute;
                    opacity: .8;
                }


                .tags-scroll:before {
                    content: "";
                    display: block;
                    height: 44px;
                    width: 1px;
                    right: 0px;
                    bottom: 12px;
                    background-color: $primary-color;
                    position: absolute;
                    z-index: 1;
                }



                .tags span:last-child,
                .roles span:last-child {
                    display: none;
                }

                .badge {
                    background-color: $secondary-color;
                    color: $primary-color;
                    border: 1px solid $primary-color;
                    opacity: 1;
                    padding: 10px;
                    font-weight: normal;
                    border-radius: 2px;
                    margin-bottom: 3px;
                }

                .badge.active {
                    background-color: $primary-color;
                    border: 1px solid $primary-color;
                    color: $secondary-color;
                }


                ////////////////////////////////////////////////////////////////////////////////////
                //LOADING BLOCK
                ////////////////////////////////////////////////////////////////////////////////////

                .loading-text {
                    font-size: 13px;
                    letter-spacing: 2px;
                    font-weight: 500;
                    border-radius: 6px;
                    padding: 10px;
                    width: 220px;
                    height: 40px;
                    position: fixed;
                    margin: auto;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    display: block;
                    background: $secondary-color;
                }

                span[class^="dot-"] {
                    opacity: 0;
                }

                .dot-one {
                    animation: dot-one 2s infinite linear
                }

                .dot-two {
                    animation: dot-two 2s infinite linear
                }

                .dot-three {
                    animation: dot-three 2s infinite linear
                }

                @keyframes dot-one {
                    0% {
                        opacity: 0;
                    }

                    15% {
                        opacity: 0;
                    }

                    25% {
                        opacity: 1;
                    }

                    100% {
                        opacity: 1;
                    }
                }

                @keyframes dot-two {
                    0% {
                        opacity: 0;
                    }

                    25% {
                        opacity: 0;
                    }

                    50% {
                        opacity: 1;
                    }

                    100% {
                        opacity: 1;
                    }
                }

                @keyframes dot-three {
                    0% {
                        opacity: 0;
                    }

                    50% {
                        opacity: 0;
                    }

                    75% {
                        opacity: 1;
                    }

                    100% {
                        opacity: 1;
                    }
                }

                ////////////////////////////////////////////////////////////////////////////////////
                //BLOCKS - HOME PAGE
                ////////////////////////////////////////////////////////////////////////////////////

                /*-------------------- content blocks --------------------*/
                .block-icon {
                    width: 90px;
                }

                .block-icon-above {
                    margin-bottom: 28px;
                }


                /*-------------------- latest blocks --------------------*/
                .block-latest {
                    a.latest-block-link {
                        color: $secondary-color;
                        border: 1px solid $secondary-color;
                        border-radius: 6px !important;
                        opacity: 1;
                        transition: all .5s;

                        p {
                            font-size: 13px;
                        }

                        .tag {
                            font-size: 12px;
                            opacity: .5;
                            line-height: 13px;
                            letter-spacing: 1px;
                        }

                        &:hover {
                            text-decoration: none;
                            opacity: .5;
                            transform: translate(0, -10px);
                        }
                    }

                    .latest-block-link span:last-child {
                        display: none;
                    }

                }


                ////////////////////////////////////////////////////////////////////////////////////
                //COLUMNS
                ////////////////////////////////////////////////////////////////////////////////////
                .editorial-2-column {
                    a {
                        color: $tertiary-color;
                        text-decoration: underline;

                        &:hover {
                            color: $primary-color;
                            text-decoration: none;
                        }
                    }
                }

                .editorial-2-column:after {
                    content: none;
                }

                a.feature-read-more-link {
                    background-color: $tertiary-color;
                    padding: 4px 10px;
                    display: inline-block;
                    border-radius: 2px;
                    color: $secondary-color;
                    transition: all .4s;
                    text-decoration: none;

                    &:hover {
                        background-color: $primary-color;
                        text-decoration: none;
                        color: $secondary-color;
                    }
                }

                .feature-title-logo {
                    img {
                        width: 160px;
                    }
                }


                ////////////////////////////////////////////////////////////////////////////////////
                //CONTACT BOX
                ////////////////////////////////////////////////////////////////////////////////////
                .contact-box {
                    a {
                        font-size: 14px;
                        color: $primary-color!important;
                        text-decoration: none;
                        letter-spacing: 1px;
                        background: $secondary-color;
                        margin: 1px;
                        border-radius: 2px;
                        transition: color .4s;
                        display: block;
                        margin-bottom: 4px;
                        background-color:  lighten($light-grey-color, 6%);
                        padding: 10px;
                        transition: opacity .4s;
                        opacity: 1;

                        &:hover {
                            color: $tertiary-color;
                            opacity: .4;
                        }
                    }

        i{
            display: none;
        }
                    // span:last-of-type {
                    //     display: none;
                    // }

                }

                ////////////////////////////////////////////////////////////////////////////////////
                //TEXT
                ////////////////////////////////////////////////////////////////////////////////////


                ////////////////////////////////////////////////////////////////////////////////////
                //WORK LIST
                ////////////////////////////////////////////////////////////////////////////////////

                .block-work-item {
                    color: $primary-color;

                    &:hover {
                        color: $primary-color !important;
                        text-decoration: none;
                    }
                }

                //faded gradient
                .work-item-inner {
                    .image-gradient {
                        width: 100%;
                        z-index: 1;
                        opacity: .3;
                    }

                    .block-icon {
                        z-index: $z-2+3;
                        margin: auto;
                        left: 0;
                        right: 0;
                        top: -50px;
                        bottom: 0;
                        transition: all 1s;
                        opacity: 0;
                    }
                }

                .faded {
                    opacity: .3 !important
                }

                .block-work-item:hover {
                    .block-icon {
                        transform: translate(0px, 30px);
                        opacity: 1;
                        pointer-events: none;
                    }
                }

                .image-gradient {
                    opacity: 1;
                    transition: opacity .3s;
                    pointer-events: none;
                }

                .block-work-details {
                    .block-work-item {
                        &:hover {
                            .image-gradient {
                                opacity: 0;
                            }

                            .caption-work {
                                opacity: 0;
                            }
                        }
                    }
                }

                .block-work-item {
                    .caption-work {
                        font-family: $font-family;
                        bottom: 20px;
                        left: 20px;
                        background-color: $secondary-color;
                        padding: 8px;
                        display: inline-block;
                        z-index: 2;
                        margin-bottom: 0;
                        opacity: 1;
                        transition: opacity .7s;
                        border-radius: 1px;
                    }
                }

                .block-work-item {
                    ul {
                        padding-left: 18px;
                    }
                }



                //gutters
                .sm-gutter {
                    margin: -5px;
                }

                .sm-gutter [class*='col-'] {
                    padding-right: 5px;
                    padding-left: 5px;
                    margin-bottom: 10px;
                }

                .sm-gutter.block-work-details [class*='col-'] {
                    padding-right: 5px;
                    padding-left: 5px;
                    margin-bottom: 10px;
                }

                .pending-message {
                    position: absolute;
                    display: inline-block;
                    background: $secondary-color;
                    border-radius: 4px;
                    margin: auto;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    width: 100px;
                    height: 40px;
                    line-height: 40px;
                    text-align: center;
                    font-size: 12px;
                    letter-spacing: 1px;
                    z-index: 1;
                }

                .block-work-item {
                    .loading {
                        min-height: 200px;
                        background-color: $light-grey-color;
                        background-repeat: no-repeat;
                        background-position: center center;


                        img {
                            display: none;
                        }

                        .image-gradient {
                            opacity: 0 !important;
                        }

                        img:last-child {
                            width: 100%;
                            animation-duration: 3s;
                        }
                    }
                }

                ////////////////////////////////////////////////////////////////////////////////////
                //PAGINATION
                ////////////////////////////////////////////////////////////////////////////////////
                .pagination {
                    li {
                        border: none !important;

                        a,
                        span {
                            color: $tertiary-color;
                        }

                        a {}
                    }

                    .page-item.active .page-link {
                        background-color: $primary-color;
                        border-color: $primary-color;
                    }
                }

                .pagination a,
                .pagination span {
                    padding: 8px 15px !important;
                }


                .paging-fixed{
                    position: fixed;
                    background-color: $white;
                    border-radius: 10px;
                    bottom: 20px;
                    left: 20px;
                    right: 20px;
                    box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
                    z-index: 1;
                }

                ////////////////////////////////////////////////////////////////////////////////////
                //SHOP
                ////////////////////////////////////////////////////////////////////////////////////

                .form-basket {
                    width: 26px;
                    height: 26px;
                    position: absolute;
                    right: 50px;
                    top: 2px;
                }


                .cost {
                    font-size: 17px;
                    margin-top: 15px;
                }


                .note-shipping {
                    font-size: 13px;
                    border: 1px solid $primary-color;
                    border-radius: 5px;

                    a {
                        color: $primary-color;
                        text-decoration: underline;

                        &:hover {
                            text-decoration: none;
                        }
                    }
                }


                table {
                    width: 100%;
                }

                .thumbnail {
                    margin-bottom: 4px;
                    display: inline-block;
                    opacity: 0.4;
                }

                .thumbnail.selected {
                    opacity: 1;
                }

                .thumbnail-image {
                    width: 50px;
                }

                .selectWrapper {
                    width: 100%;
                    overflow: hidden;
                    position: relative;
                    border: 1px solid $primary-color;
                    border-radius: 1px;
                    margin-bottom: 15px;
                    ;
                }

                .selectWrapper:after {
                    content: "";
                    display: inline-block;
                    border-right: 2px solid $primary-color;
                    border-bottom: 2px solid $primary-color;
                    width: 10px;
                    height: 10px;
                    transform: rotate(45deg);
                    position: absolute;
                    right: 13px;
                    top: 11px;
                }

                .selectWrapper select {
                    color: $primary-color;
                    padding: 10px 40px 10px 12px;
                    font-size: 16px;
                    line-height: 16px;
                    width: 100%;
                    border: none;
                    box-shadow: none;
                    background: transparent;
                    background-image: none;
                    -webkit-appearance: none;
                    outline: none;
                    cursor: pointer;
                    -moz-appearance: none;
                    text-indent: 0.01px;
                    text-overflow: ellipsis;
                }



                ////////////////////////////////////////////////////////////////////////////////////
                //FOOTER
                ////////////////////////////////////////////////////////////////////////////////////


                .footer-main {
                    font-size: 11px;
                    letter-spacing: 1px;
                    padding: 0 $inner-padding-xs !important;
                    margin-top: 0;
                    z-index: 3000;
                    position: relative;

                    a {
                        color: $primary-color;
                    }
                }


                ////////////////////////////////////////////////////////////////////////////////////
                //SOCIAL
                ////////////////////////////////////////////////////////////////////////////////////
                .social-links {
                    a {
                        color: $primary-color;
                        text-decoration: underline;
                        letter-spacing: 1px;
                        opacity: 1;
                        transition: opacity .4s;

                        i {}

                        &:hover {
                            text-decoration: none;
                            color: $tertiary-color;
                            opacity: .4;
                        }
                    }

                }

                .social-links-about {
                    a {
                        display: block;
                        text-decoration: none;

                        i {
                            display: none;

                        }

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }


                .social-links-footer {
                    a {
                        padding: 0;
                        transition: color .4s;
                        font-size: 11px;
                        text-decoration: none;

                        i {
                            display: none;
                        }

                        &:hover {
                            text-decoration: none;
                            text-decoration: none;
                        }
                    }

                    a:after {
                        content: "|";
                        margin: 0 5px;
                    }

                    a:last-child:after {
                        content: "";
                    }
                }

                .social-links-menu {
                    a {
                        display: block;
                        font-size: 14px;

                        i {
                            display: none;
                        }

                        &:hover {}
                    }
                }

                .social-links-navbar {
                    position: absolute;
                    left: 0;
                    z-index: 1;
                    top: 0;

                    a {
                        span {
                            display: none;
                        }

                        i {
                            border: 1px solid $primary-color;
                            border-radius: 100%;
                            width: 30px;
                            height: 30px;
                            line-height: 27px;
                            text-align: center;
                            font-size: 15px;
                            display: inline-block;


                        }

                        &:hover {}
                    }
                }


                .navigation-middle {}

                .brand-container {
                    display: flex;
                    justify-content: center;
                    position: absolute;
                    width: 100%;
                    left: 0;
                    top: -2px;
                }

                ////////////////////////////////////////////////////////////////////////////////////
                //BUTTONS
                ////////////////////////////////////////////////////////////////////////////////////

                .btn {
                    opacity: 1;
                    transition: opacity .4s;

                    &:hover {
                        opacity: .4;
                    }
                }

                .btn-outline {
                    border: 1px solid var(--theme-color);
                    color: $primary-color;

                    &:hover {
                        border: 1px solid $primary-color;
                        color: $primary-color;
                    }
                }

                button[type=reset] {
                    border: 1px solid $primary-color;
                    color: $primary-color;
                    background-color: transparent;
                    font-size: 14px;
                    &:hover{
                        background-color: transparent;
                        color: $primary-color;
                        border: 1px solid $primary-color;
                    }
                }
                button[type=submit] {
                    color: $secondary-color;
                    background-color: $primary-color;
                    border: 1px solid $primary-color;
                    font-size: 14px;
                    &:hover{
         
                        color: $secondary-color;
                        background-color: $primary-color;
                        border: 1px solid $primary-color;
                    }
                }


                .btn-white-outline {
                    border-color: $secondary-color;
                    color: $secondary-color;

                    &:hover {
                        border-color: $secondary-color;
                        color: $secondary-color;
                    }
                }

                .btn-prev,
                .btn-next {
                    font-size: 12px;
                    padding: 0;
                    color: $primary-color;
                    opacity: 1;
                    display: inline;

                    &:hover {
                        color: $primary-color;
                    }
                }

                .btn-visit {
                    background-color: var(--theme-color) !important;
                    border-color: var(--theme-color) !important;
                    border-radius: 2px !important;
                    letter-spacing: 1px;
                    margin-top: 20px;
                    opacity: 1;
                    color: $secondary-color !important;
                    text-decoration: none !important;
                    font-size: 13px !important;
                }

                .back-to-top {
                    position: fixed;
                    display: none;
                    background-color: $tertiary-color;
                    bottom: 10px;
                    z-index: 3000;
                    right: $inner-padding-xs;
                    width: 44px;
                    height: 44px;

                    i {
                        display: block;
                        font-size: 19px;
                        opacity: 1;
                        color: $secondary-color;
                    }

                    &:focus {
                        outline: none;
                        box-shadow: none;
                    }

                    &:hover {
                        background-color: $primary-color;

                        i {
                            color: $secondary-color;
                        }
                    }
                }

                .btn-cta{
                    border: 1px solid $secondary-color
                }

                .btn-link-white{
                    color: $secondary-color;
                    transition: opacity .4s;
                    opacity: 1;
                    text-decoration: none;
                    &:hover{
                        opacity: .4;
                        color: $secondary-color; 
                        text-decoration: none;
                    }
                }

                ////////////////////////////////////////////////////////////////////////////////////
                //FORM
                ////////////////////////////////////////////////////////////////////////////////////
                
                
                .column-right  div:not(.form-label ){
                    height: 100%;
                }
                .column-left .form-field:last-child{
                    // margin-bottom: 0;
                }
                .column-right{
                    textarea{
                        min-height: 200px;
                    }
            
                }
                
.form-control{
    color: $primary-color;
    font-size: 14px;
    border: 1px solid $primary-color;
}

.notices.red {
    background: #d9534f!important;
    color: white!important;
    border: 1px solid #d9534f!important;
    border-radius: 10px!important;
    padding: 15px!important;
    margin-bottom: 20px;
    p{
        margin: 0;
    }
    }

    .notices.green {
        background: #00882b!important;
        color: white!important;
        border: 1px solid #00882b!important;
        border-radius: 10px!important;
        padding: 15px!important;
        margin-bottom: 20px;
        p{
            margin: 0;
        }
        }


        .form-group.has-errors {
            background: transparent!important;
            border: none!important;
            border-radius: 3px;
            margin: 0 0 1rem 0!important;
            padding: 0!important;
            input, textarea{
                border: 1px solid #d9534f!important;
            }
            }

                ////////////////////////////////////////////////////////////////////////////////////
                //MODAL
                ////////////////////////////////////////////////////////////////////////////////////
                .modal.modal-fullscreen .modal-dialog,
                .modal.modal-fullscreen .modal-content {
                    bottom: 0;
                    left: 0;
                    position: absolute;
                    right: 0;
                    top: 0;
                }

                .modal.modal-fullscreen .modal-dialog {
                    margin: 0;
                    width: 100%;
                }

                .modal.modal-fullscreen .modal-content {
                    border: none;
                    -moz-border-radius: 0;
                    border-radius: 0;
                    -webkit-box-shadow: inherit;
                    -moz-box-shadow: inherit;
                    -o-box-shadow: inherit;
                    box-shadow: inherit;
                }

                .modal.modal-fullscreen.force-fullscreen .modal-body {
                    padding: 0;
                }

                .modal {
                    z-index: $z-6+1 !important;
                }

                .modal-backdrop.show {
                    background-color: $secondary-color !important;
                    opacity: .9 !important;
                    z-index: $z-6 !important;
                    margin: 0;
                    padding: 0;
                }

                .modal-content {
                    background: transparent !important;
                }


                ////////////////////////////////////////////////////////////////////////////////////
                //VIDEO
                ////////////////////////////////////////////////////////////////////////////////////
                .video {
                    margin: auto;
                    max-width: 80%;
                    max-height: 60%;
                    border-radius: 10px;
                    background-color: $primary-color;
                }

                .btn-close-showreel {
                    position: fixed;
                    color: $secondary-color;
                    text-shadow: none;
                    line-height: 44px;
                    text-align: center;
                    margin: auto;
                    opacity: 1;
                    transition: opacity .4s;
                    background-color: $primary-color !important;
                    display: inline-block;
                    font-size: 12px;
                    border-radius: 3px;
                    color: $secondary-color;
                    padding: 0 15px !important;
                    letter-spacing: 1px;
                    opacity: 1;
                    transition: opacity .4s;

                    &:focus {
                        outline: none;
                        border: none;
                        box-shadow: none;
                    }

                    &:hover {
                        opacity: .5;
                        color: $secondary-color;
                    }
                }

                ////////////////////////////////////////////////////////////////////////////////////
                //PRELOAD IMAGES
                ///////////////////////////////////////////////////////////////////////////////////
                div#preloaded-images {
                    position: absolute;
                    overflow: hidden;
                    left: -9999px;
                    top: -9999px;
                    height: 1px;
                    width: 1px;
                }

                ////////////////////////////////////////////////////////////////////////////////////
                //STORIES PAGE
                ////////////////////////////////////////////////////////////////////////////////////

                //all story articles
                .story-block {
                    padding-top: 50px;

                    article {
                        border-top: 1px solid $primary-color;
                        padding-top: 50px;
                    }

                    article:first-child {
                        border-top: none;
                        padding-top: 0;
                    }

                    img {
                        max-width: 100%;
                        height: auto;
                        margin-top: 15px;
                        margin-bottom: 15px;
                    }

                    h3 {
                        margin-top: 0;
                        font-size: 25px;
                        text-transform: uppercase;
                    }

                    h4 {
                        margin-top: 30px;
                        font-size: 20px;
                        font-weight: 600;
                    }

                    blockquote {
                        background-color: $light-grey-color;
                        border-radius: 5px;
                        padding: 20px;
                        -webkit-column-break-inside: avoid;
                        /* Chrome, Safari, Opera */
                        page-break-inside: avoid;
                        /* Firefox */
                        break-inside: avoid;
                        /* IE 10+ */
                        font-style: italic;
                    }

                    a {
                        color: $primary-color;
                        text-decoration: underline;

                        &:hover {
                            text-decoration: none;
                        }
                    }

                    ul {
                        padding-left: 20px;
                    }
                }

                .text-columns {
                    padding-top: 0px;
                    padding-bottom: 50px;
                    -webkit-column-count: 1;
                    -moz-column-count: 1;
                    column-count: 1;
                }

                @media (min-width: 576px) {}

                @media (min-width: 768px) {
                    .columns-4 {
                        -webkit-column-count: 2;
                        -moz-column-count: 2;
                        column-count: 2;
                    }
                }

                @media (min-width: 992px) {
                    .columns-4 {
                        -webkit-column-count: 3;
                        -moz-column-count: 3;
                        column-count: 3;
                    }

                    .columns-3 {
                        -webkit-column-count: 2;
                        -moz-column-count: 2;
                        column-count: 2;
                    }

                    .columns-2 {
                        -webkit-column-count: 2;
                        -moz-column-count: 2;
                        column-count: 2;
                    }
                }

                @media (min-width: 1200px) {}

                @media (min-width: 1500px) {
                    .columns-4 {
                        -webkit-column-count: 4;
                        -moz-column-count: 4;
                        column-count: 4;
                    }

                    .columns-3 {
                        -webkit-column-count: 3;
                        -moz-column-count: 3;
                        column-count: 3;
                    }

                    .columns-2 {
                        -webkit-column-count: 2;
                        -moz-column-count: 2;
                        column-count: 2;
                    }
                }


                ////////////////////////////////////////////////////////////////////////////////////
                //MEDIA QUERIES
                ////////////////////////////////////////////////////////////////////////////////////
                @import "media-queries.scss";