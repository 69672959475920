/*
####################################################
M E D I A  Q U E R I E S
####################################################
*/


/* 
Extra small devices (portrait phones, less than 576px) 
No media query since this is the default in Bootstrap because it is "mobile first"
*/


/* Small devices (landscape phones, 576px and up) */

@media (min-width: 576px) {

    //HEADINGS
    h1 {
        font-size: 50px;
        line-height: 50px;
    }

    //SHOP
    .thumbnail-image {
        width: 50px;
    }

    table {
        width: auto;
    }

    .block-main {
        padding: $inner-padding-xs;
    }

    .modal-dialog {
        max-width: none !important;
    }

    //TOP BUTTON
    .back-to-top {
        right: $inner-padding-xs;
        bottom: 100px;
    }

    //IMAGE GRADIENT
    .image-gradient {
        height: 100%;
        top: 0;
        bottom: auto;
    }
}


/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */

@media (min-width: 768px) {

    .btn-prev,
    .btn-next {
        font-size: 14px;
    }

    .form-basket {
        position: relative;
        margin-left: 30px;
        right: auto;
    }

    //NAV
    .container-nav {
        padding-left: $inner-padding-sm;
        padding-right: $inner-padding-sm;
    }

    .nav-link {
        font-size: 14px;
    }

    .nav-item {
        padding-top: 0;
        padding-bottom: 0;
    }

    .dropdown-item {
        padding: 3px 15px;
        font-size: 14px;
    }


    //CONTACT DETAILS LINK
    .nav-item-contact{
        .nav-link{
            background-color: var(--theme-color);
            padding: 5px 10px!important;
            border-radius: 3px;
            color: $secondary-color!important;
            opacity: 1;
            transition: all .4s;
            &:hover{
                color: $secondary-color!important;
                opacity: .5;
            }
        }
    }

    .nav-item.nav-item-contact:after {
        content: none;
        }

    //HEADER
    .block-main {
        border-width: $inner-padding-sm;
        padding: $inner-padding-sm;
    }

    //FOOTER
    .footer-main {
        padding: 0 $inner-padding-sm !important;
        height: $inner-padding-sm;
        margin-top: -$inner-padding-sm;
        line-height: $inner-padding-sm;
    }

    //COLUMNS
    .editorial-2-column {
        columns: 2 150px;
        column-gap: 6em;
        position: relative;
    }

    .editorial-2-column:after {
        content: "";
        width: 1px;
        background-color: $primary-color;
        height: 100%;
        display: block;
        position: absolute;
        left: 50%;
        top: 0;
        bottom: 0;
    }

    //TOP BUTTON
    .back-to-top {
        right: $inner-padding-sm;
    }

    //TAGS SCROLL
    .tags-scroll-internal {
        position: relative;
        overflow-x: none;
        overflow-y: auto;
        height: auto;
        white-space: normal;
    }

    .tags-scroll-internal a:last-child {
        margin-right: 0;
    }

    .tags-scroll:after {
        display: none;
    }

    .tags-scroll:before {
        display: none;
    }

}


/* Large devices (desktops, 992px and up) */

@media (min-width: 992px) {

    //NAV
    .container-nav {
        padding-left: $inner-padding-md;
        padding-right: $inner-padding-md;
    }

    //HEADER
    .block-main {
        border-width: $inner-padding-md;
        padding: $inner-padding-md;
    }

    .header-text {
        margin-bottom: 0;
    }

    //CONTACT BOX
    .contact-box {


    }

    //FOOTER
    .footer-main {
        padding: 0 $inner-padding-md !important;
        height: $inner-padding-md;
        margin-top: -$inner-padding-md;
        line-height: $inner-padding-md;
    }

    //TOP BUTTON
    .back-to-top {
        right: $inner-padding-md;
    }

    //COLUMNS
    .editorial-2-column {
        columns: 2 150px;
        column-gap: 4em;
        position: relative;
    }
}


/* Extra large devices (large desktops, 1200px and up) */

@media (min-width: 1200px) {
    .profile-pic {
        max-width: 310px;
    }

    //NAV
    .container-nav {
        padding-left: $inner-padding-lg;
        padding-right: $inner-padding-lg;
    }

    //HEADER
    .block-main {
        border-width: $inner-padding-lg;
        padding: $inner-padding-lg;
    }

    //FOOTER
    .footer-main {
        padding: 0 $inner-padding-lg !important;
        height: $inner-padding-lg;
        margin-top: -$inner-padding-lg;
        line-height: $inner-padding-lg;
    }

    //TOP BUTTON
    .back-to-top {
        right: $inner-padding-lg;
        bottom: 144px;
    }
}

@media (min-width: 1500px) {}


/*
::::::::::::::::::::::::::::::::::::::::::::::::::::
Custom media queries
*/

@media (max-width: 767px) {

    //NAVBAR
    .nav-item:after {
        display: none;
    }

    .nav-item::before {
        position: absolute;
        display: block;
        top: 20px;
        left: 1px;
        width: 11px;
        height: 1px;
        content: "";
        border: none;
        background-color: $primary-color;
        vertical-align: 0;
    }

    .dropdown-toggle::after {
        position: absolute;
        display: block;
        top: 10px;
        left: -23px;
        width: 1px;
        height: 11px;
        content: "";
        border: none;
        background-color: $primary-color;
        vertical-align: 0;
        transition: all 200ms linear;
    }

    .dropdown-toggle[aria-expanded="true"]::after {
        transform: rotate(90deg);
        opacity: 0;
    }

    .dropdown-menu {
        padding: 0 !important;
        background-color: transparent;
        box-shadow: none;
        transition: all 200ms linear;
    }

    .dropdown-toggle[aria-expanded="true"]+.dropdown-menu {
        margin-top: 10px !important;
        margin-bottom: 20px !important;
    }

    .dropdown-select.show {
        .dropdown-toggle[aria-expanded="true"]+.dropdown-menu {
            margin-top: 0px !important;
            margin-bottom: 0px !important;
        }
    }
}


/* Set width to make card deck cards 100% width */

@media (max-width: 768px) {
    .home {
        .block-main {
            border-bottom: 0 !important;
        }

        .footer-main {
            height: 0 !important;
            line-height: 30px;
            margin: 30px 0 !important;
            height: 50px !important;
        }
    }

}

//ipad pro portrait - Apple iPad Pro 12.9 (2018) Portrait Media Queries
@media only screen and (min-width: 1024px) and (orientation: portrait) {}