.icon-20 {
    font-size: 20px;
}

.icon-30 {
    font-size: 30px;
}

.icon-40 {
    font-size: 40px;
}

.icon-50 {
    font-size: 50px;
}

.icon-60 {
    font-size: 60px;
}

.icon-70 {
    font-size: 70px;
}

.icon-80 {
    font-size: 80px;
}

.icon-90 {
    font-size: 90px;
}

.icon-100 {
    font-size: 100px;
}

.icon-110 {
    font-size: 110px;
}

.icon-120 {
    font-size: 120px;
}

.icon-130 {
    font-size: 130px;
}

.icon-140 {
    font-size: 140px;
}

.icon-150 {
    font-size: 150px;
}