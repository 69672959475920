$if-font-path: "../icon-font";
@font-face {
    font-family: "icon-font";
    src: url('#{$if-font-path}/icon-font.eot');
    src: url('#{$if-font-path}/icon-font.eot?#iefix') format('eot'),
    url('#{$if-font-path}/icon-font.woff2') format('woff2'),
    url('#{$if-font-path}/icon-font.woff') format('woff'),
    url('#{$if-font-path}/icon-font.ttf') format('truetype'),
    url('#{$if-font-path}/icon-font.svg#icon-font') format('svg');
}

.icon-font {
    vertical-align: -3px;
}

@mixin icon-styles {
    font-family: "icon-font";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    // speak: none; // only necessary if not using the private unicode range (firstGlyph option)
    text-decoration: none;
    text-transform: none;
}

%icon {
    @include icon-styles;
}

@function icon-char($filename) {
    $char: "";
    @if $filename==chat {
            $char: "\E001";
        }
        @if $filename==chevron-down {
            $char: "\E002";
        }
        @if $filename==chevron-left {
            $char: "\E003";
        }
        @if $filename==chevron-right {
            $char: "\E004";
        }
        @if $filename==chevron-up {
            $char: "\E005";
        }
        @if $filename==close {
            $char: "\E006";
        }
        @if $filename==email {
            $char: "\E007";
        }
        @if $filename==etsy {
            $char: "\E008";
        }
        @if $filename==facebook {
            $char: "\E009";
        }
        @if $filename==house {
            $char: "\E00A";
        }
        @if $filename==instagram {
            $char: "\E00B";
        }
        @if $filename==linkedin {
            $char: "\E00C";
        }
        @if $filename==phone {
            $char: "\E00D";
        }
        @if $filename==play {
            $char: "\E00E";
        }
        @if $filename==star {
            $char: "\E00F";
        }
        @if $filename==twitter {
            $char: "\E010";
        }
        @return $char;
}

@mixin icon($filename,
$insert: before,
$extend: true) {
    &:#{$insert} {
        @if $extend {
            @extend %icon;
        }
        @else {
            @include icon-styles;
        }
        content: icon-char($filename);
    }
}

.icon-chat {
        @include icon(chat);
    }
    .icon-chevron-down {
        @include icon(chevron-down);
    }
    .icon-chevron-left {
        @include icon(chevron-left);
    }
    .icon-chevron-right {
        @include icon(chevron-right);
    }
    .icon-chevron-up {
        @include icon(chevron-up);
    }
    .icon-close {
        @include icon(close);
    }
    .icon-email {
        @include icon(email);
    }
    .icon-etsy {
        @include icon(etsy);
    }
    .icon-facebook {
        @include icon(facebook);
    }
    .icon-house {
        @include icon(house);
    }
    .icon-instagram {
        @include icon(instagram);
    }
    .icon-linkedin {
        @include icon(linkedin);
    }
    .icon-phone {
        @include icon(phone);
    }
    .icon-play {
        @include icon(play);
    }
    .icon-star {
        @include icon(star);
    }
    .icon-twitter {
        @include icon(twitter);
    }
    