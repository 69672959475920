.svg-20 {
    width: 20px;
}

.svg-30 {
    width: 30px;
}

.svg-40 {
    width: 40px;
}

.svg-50 {
    width: 50px;
}

.svg-60 {
    width: 60px;
}

.svg-70 {
    width: 70px;
}

.svg-80 {
    width: 80px;
}

.svg-90 {
    width: 90px;
}

.svg-100 {
    width: 100px;
}

.svg-110 {
    width: 110px;
}

.svg-120 {
    width: 120px;
}

.svg-130 {
    width: 130px;
}

.svg-140 {
    width: 140px;
}

.svg-150 {
    width: 150px;
}